import { Modal, Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import { useBackendUrl, useEffectOnce } from "../utils/hooks";
import NumberCustomInput from "./utils/NumberCustomInput";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { qtyFormat, Units } from "../utils/helpers";
import { appSettings } from "../config";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { useStoreActions, useStoreState } from "easy-peasy";
import Select from "react-select";
import ItemSizeDialog from "./ItemSizeDialog";
import { useEffect } from "react";
import currency from "currency.js";

export default function UpdateItemPriceModal({
  showUpdatePriceModal,
  selectedItem,
  setSelectedItem,
  setShowUpdatePriceModal,
  refetch,
}) {
  const backendUrl = useBackendUrl();
  const itemMeasurements = useStoreState((state) =>
    state.itemMeasurements.map((el) => ({ label: el.Size, value: el.Size }))
  );

  const setItemMeasurements = useStoreActions(
    (actions) => actions.setItemMeasurements
  );

  const initialValues = {
    UnitPrice: 0, // walk / retail customer
    Vat_5: 0, // distributor
    Tax: 0, // unit price in pieces in gigc, wholesale in normal invex
    Unit: "Each",
    Size: "",

    //--------------
    Margin: 0,
    Incentive: 0,
    UnitCost: 0,
  };
  const schema = yup.object().shape({
    UnitPrice: yup.string().required("required"),
    Tax: yup.string().required("required"),
    Vat_5: yup.string().required("required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      updatePriceMutation.mutate({
        ...values,
        Bar_Code: selectedItem.Bar_Code,
      });
    },
  });

  const setUp = () => {
    formik.setValues({
      UnitPrice: selectedItem.UnitPrice,
      Tax: selectedItem.Tax,
      Vat_5: selectedItem.Vat_5,
      Unit: selectedItem.Product_Model,
      Size: selectedItem.Item_Desc,

      //----------------
      Margin: selectedItem.Margin,
      Incentive: selectedItem.Incentive,
      UnitCost: selectedItem.UnitCost,
    });
  };

  useEffectOnce(() => {
    setUp();
  });

  const updatePrice = async (payload) => {
    let response = await fetch(`${backendUrl}/api/items/update-price`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const updatePriceMutation = useMutation((payload) => updatePrice(payload), {
    onSuccess: ({ message, data }) => {
      refetch();
      toast.success(message);
      setSelectedItem(null);
      setShowUpdatePriceModal(false);
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const addItemMeasurement = async () => {
    const newSize = await ItemSizeDialog();
    if (newSize) {
      formik.setFieldValue("Size", newSize.Size);
      setItemMeasurements([...itemMeasurements, newSize]);
    }
  };

  //Incentive & Margin
  useEffect(() => {
    if (appSettings.hasIncentive) {
      const UnitPrice = currency(formik.values.UnitCost)
        .add(formik.values.Incentive)
        .add(formik.values.Margin).value;

      formik.setFieldValue("UnitPrice", UnitPrice);
      formik.setFieldValue("Tax", UnitPrice);
      formik.setFieldValue("Vat_5", UnitPrice);
    }
  }, [formik.values.UnitCost, formik.values.Margin, formik.values.Incentive]);

  return (
    <Modal
      show={showUpdatePriceModal}
      onHide={() => {
        setSelectedItem(null);
        setShowUpdatePriceModal(false);
      }}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h6">Edit {selectedItem?.Item_Name}</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          {appSettings.hasIncentive && (
            <>
              <Form.Group className="mb-3">
                <Form.Label>UnitCost</Form.Label>
                <CurrencyCustomInput
                  type="text"
                  placeholder="Enter Unit Cost"
                  name="UnitCost"
                  value={formik.values.UnitCost}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={
                    formik.touched.UnitCost && !!formik.errors.UnitCost
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.UnitCost}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="row">
                <Form.Group className="col-md-6 mb-3 pb-2">
                  <Form.Label className="mb-1">Profit</Form.Label>
                  <CurrencyCustomInput
                    name="Margin"
                    placeholder="0.00"
                    value={formik.values.Margin}
                    onValueChange={(value, name) => {
                      formik.setFieldValue(name, value);
                    }}
                    isInvalid={formik.touched.Margin && !!formik.errors.Margin}
                    onBlur={() => formik.setFieldTouched("Margin", true)}
                  />
                  {formik.touched.Margin && !!formik.errors.Margin ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.Margin}
                    </span>
                  ) : null}
                </Form.Group>

                <Form.Group className="col-md-6 mb-3 pb-2">
                  <Form.Label className="mb-1">Incentive</Form.Label>
                  <CurrencyCustomInput
                    name="Incentive"
                    placeholder="0.00"
                    value={formik.values.Incentive}
                    onValueChange={(value, name) => {
                      formik.setFieldValue(name, value);
                    }}
                    isInvalid={
                      formik.touched.Incentive && !!formik.errors.Incentive
                    }
                    onBlur={() => formik.setFieldTouched("Incentive", true)}
                  />
                  {formik.touched.Incentive && !!formik.errors.Incentive ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.Incentive}
                    </span>
                  ) : null}
                </Form.Group>
              </div>
            </>
          )}

          <Form.Group className="mb-3">
            <Form.Label>
              UnitPrice{" "}
              {selectedItem.Product_Model === "Tons"
                ? "(Tons)"
                : `(Retail / Walk In)`}
            </Form.Label>

            <CurrencyCustomInput
              type="text"
              name="UnitPrice"
              value={formik.values.UnitPrice}
              onValueChange={(value, name) => {
                formik.setFieldValue(name, value);
              }}
              isInvalid={formik.touched.UnitPrice && !!formik.errors.UnitPrice}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.UnitPrice}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              UnitPrice
              {selectedItem.Product_Model !== "Tons"
                ? `(Wholesale)`
                : `(Pieces)`}
            </Form.Label>
            <CurrencyCustomInput
              type="text"
              name="Tax"
              value={formik.values.Tax}
              onValueChange={(value, name) => {
                formik.setFieldValue(name, value);
              }}
              isInvalid={formik.touched.Tax && !!formik.errors.Tax}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.UnitPrice}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>UnitPrice (Distributor)</Form.Label>
            <CurrencyCustomInput
              type="text"
              name="Vat_5"
              value={formik.values.Vat_5}
              onValueChange={(value, name) => {
                formik.setFieldValue(name, value);
              }}
              isInvalid={formik.touched.Vat_5 && !!formik.errors.Vat_5}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.Vat_5}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="row text-nowrap align-items-center">
            <Form.Group className="col-5 mb-3 pb-2">
              <Form.Label className="mb-1">Unit</Form.Label>
              <Select
                classNamePrefix={`form-select`}
                options={Units}
                value={Units.find((el) => el.value === formik.values.Unit)}
                onChange={({ value }) => {
                  formik.setFieldValue("Unit", value);
                }}
              />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            {["Tons", "Pieces"].includes(formik.values.Unit) && (
              <>
                <Form.Group className="col-5 mb-3 pb-2">
                  <Form.Label className="mb-1">Size</Form.Label>
                  <Select
                    classNamePrefix={`form-select`}
                    options={itemMeasurements}
                    value={itemMeasurements.find(
                      (el) => el.value === formik.values.Size
                    )}
                    onChange={({ value }) => {
                      formik.setFieldValue("Size", value);
                    }}
                  />
                </Form.Group>
                <Form.Group className="col-2 pb-2 px-0">
                  <Button size="sm" onClick={() => addItemMeasurement()}>
                    + Add
                  </Button>
                </Form.Group>
              </>
            )}
          </div>

          <Button
            disabled={updatePriceMutation.isLoading}
            variant="primary"
            className="w-100 p-2"
            type="submit"
          >
            {updatePriceMutation.isLoading ? "Please wait…" : "Save Changes"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

/* export default function UpdateItemPriceModal(props) {
  return createConfirmation(confirmable(UpdatePrice))({ ...props });
}
 */
