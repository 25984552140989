import { createContext, useContext, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { appSettings, backendApis } from "../config";
import { useLocalStorage } from "../utils/hooks";
import { useStoreActions, useStoreState } from "easy-peasy";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  const login = async (data, location) => {
    setUser(data);
    if (location.state?.from) {
      navigate(location.state.from, { replace: true });
    } else {
      navigate("/dashboard", { replace: true });
    }
  };

  const logout = () => {
    setUser(null);
    // const cookies = new Cookies();
    // cookies.remove("token");
    // cookies.remove("company_id");
    // cookies.remove("user");
    // cookies.remove("isSuperAdmin");
    // cookies.remove("isAdmin");
    // cookies.remove("userId");
    // cookies.remove("isLoggedIn");
    // cookies.remove("isFirstTime");
    navigate("/login", { replace: true });
  };

  const value = useMemo(() => {
    const backendApi = backendApis.find((el) => el.name === user?.company);
    const isCement = ["Dangote Cement", "Bua Cement"].includes(user?.company);

    return {
      user,
      login,
      logout,
      setUser,
      token: user?.token,
      backendUrl: backendApi ? backendApi?.url : "",
      brachData: backendApi ? backendApi : "",
      isCement,
      isTransportAndLogistics: user?.company === "Transport and Logistics",
      isOilAndGas: user?.company === "Oil and Gas",
      isCrystalWireIndustries: user?.company === "CrystalWire Industries",
      isGIGCHoldings: user?.company === "GIGC Holdings",
      isIronRod:
        ["Iron Rods", "Iron Rods - Akure"].includes(user?.company) ||
        (appSettings.isBatchStandard && !isCement),
      // isIronRod: true,
    };
  }, [user, user?.company]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
