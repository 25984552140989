import { useFormik } from "formik";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal, Form, Nav, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import * as yup from "yup";
import EyeOffOutline from "mdi-react/EyeOffOutlineIcon";
import EyeOutline from "mdi-react/EyeOutlineIcon";

import useDebounce, {
  useBackendUrl,
  useEffectOnce,
  useIsAdmin,
} from "../utils/hooks";
import Avatar from "./utils/Avatar";
import { useAuth } from "../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import { GearIcon } from "./Icons";
import { useMutation, useQuery } from "react-query";
import { initialGeneralSettings } from "../utils/helpers";
import Select from "react-select";

export default function GeneralSettingsModal({
  showGeneralSettingsModal,
  setShowGeneralSettingsModal,
}) {
  const { backendUrl } = useAuth();
  const isAdmin = useIsAdmin();
  //const generalSettings = useStoreState((state) => state.generalSettings);
  const setGeneralSettings = useStoreActions(
    (actions) => actions.setGeneralSettings
  );

  const addSettings = async (payload) => {
    let response = await fetch(`${backendUrl}/api/users/company-settings`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const addSettingsMutation = useMutation((payload) => addSettings(payload), {
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const formik = useFormik({
    initialValues: initialGeneralSettings,
    onSubmit: (values) => {
      //  Save  to  server
      addSettingsMutation.mutate(
        { settings: values },
        {
          onSuccess: ({ message, data }) => {
            setGeneralSettings(values);
            toast.success("Settings saved");
            setShowGeneralSettingsModal(false);
          },
        }
      );
    },
  });

  const setUp = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/users/company/settings`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    if (data.settings) {
      formik.setValues({ ...initialGeneralSettings, ...data.settings });
    }

    return data;
  };

  const { data, isFetching } = useQuery(
    ["GET_GLOBAL_SETTINGS"],
    () => setUp(),
    {
      enabled: true,
    }
  );

  return (
    <>
      <Modal
        size="md"
        show={showGeneralSettingsModal}
        onHide={() => setShowGeneralSettingsModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">
            <GearIcon /> Settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            onSubmit={formik.handleSubmit}
            autoComplete="off"
            style={isFetching ? { opacity: "0.5", pointerEvents: "none" } : {}}
          >
            <p className="mb-3">Personal</p>
            <Form.Group>
              <Form.Check
                inline
                id="convertToTonsAndPcs"
                label="Convert Quantity to Tons and pcs"
                name="convertToTonsAndPcs"
                type={"checkbox"}
                checked={formik.values.convertToTonsAndPcs}
                onChange={formik.handleChange}
              />
            </Form.Group>

            {isAdmin ? (
              <div>
                <hr />

                <p className="mb-3">All Users</p>
                <Form.Group className="mb-3">
                  <Form.Check
                    inline
                    id="enableCreditLimit"
                    label="Enable Customers Credit Limit"
                    name="enableCreditLimit"
                    type={"checkbox"}
                    checked={formik.values.enableCreditLimit}
                    onChange={formik.handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    inline
                    id="enableCreditPaymentForWalkIn"
                    label="Allow Credit Invoice for Walk-In Customer"
                    name="enableCreditPaymentForWalkIn"
                    type={"checkbox"}
                    checked={formik.values.enableCreditPaymentForWalkIn}
                    onChange={formik.handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Invoice workflow</Form.Label>

                  <div>
                    <Form.Check
                      inline
                      label="Proforma Invoice"
                      value="Proforma Invoice"
                      name="pendingInvoiceType"
                      type={"radio"}
                      id="Proforma Invoice"
                      checked={
                        formik.values.pendingInvoiceType === "Proforma Invoice"
                      }
                      onChange={formik.handleChange}
                    />
                    <Form.Check
                      inline
                      label="Quotation"
                      value="Quotation"
                      name="pendingInvoiceType"
                      type={"radio"}
                      id="Quotation"
                      checked={formik.values.pendingInvoiceType === "Quotation"}
                      onChange={formik.handleChange}
                    />
                  </div>
                </Form.Group>

                {/* <Form.Group className="mb-3">
                  <Form.Label className="mb-1 text-nowrap">Currency</Form.Label>
                  <Select
                    classNamePrefix={"form-select"}
                    isSearchable={false}
                     value={didYouKnowOptions.find(
                      (el) => el.value === formik.values.How_Did_Know
                    )}
                    onChange={({ value }) =>
                      formik.setFieldValue("How_Did_Know", value)
                    }
                    options={didYouKnowOptions}
                  />
                  <Form.Control.Feedback type="invalid" />
                </Form.Group> */}

                <Form.Group className="mb-3">
                  <Form.Check
                    inline
                    id="hideDiscountColumn"
                    label="Hide Discount Column on Invoice"
                    name="hideDiscountColumn"
                    type={"checkbox"}
                    checked={formik.values.hideDiscountColumn}
                    onChange={formik.handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check
                    inline
                    id="addSignatureOnInvoice"
                    label="Add Signature on Invoice"
                    name="addSignatureOnInvoice"
                    type={"checkbox"}
                    checked={formik.values.addSignatureOnInvoice}
                    onChange={formik.handleChange}
                  />
                </Form.Group>
              </div>
            ) : null}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => formik.submitForm()}
            variant="primary"
            disabled={addSettingsMutation.isLoading}
          >
            {addSettingsMutation.isLoading ? "Please wait..." : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
