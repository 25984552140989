import { Modal, Form, Button, InputGroup } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import { useBackendUrl, useEffectOnce } from "../utils/hooks";
import NumberCustomInput from "./utils/NumberCustomInput";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { qtyFormat, tonsToPcs, Units, unitsResolver } from "../utils/helpers";
import { appSettings } from "../config";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { useStoreActions, useStoreState } from "easy-peasy";
import Select from "react-select";
import ItemSizeDialog from "./ItemSizeDialog";
import { useEffect } from "react";
import currency from "currency.js";
import { Popover } from "react-tiny-popover";
import ExpensesSelector from "./utils/ExpensesSelector";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import eventBus from "../utils/EventBus";
import { useMemo } from "react";
import Datetime from "react-datetime";
import moment from "moment";
import { lowerCase } from "lodash";
import ConvertQuantity from "./utils/ConvertQuantity";
import ConfirmDialog from "./ConfirmDialogue";

const adjustmentTypeOptions = [
  {
    label: "Shortage",
    value: "shortage",
  },
  {
    label: "Excess",
    value: "excess",
  },
];

export default function QuantityAdjustmentModal({
  showQuantityAdjustModal = true,
  selectedBatchItem,
  selectedItem,
  setSelectedBatchItem,
  setShowQuantityAdjustModal,
  refetch,
}) {
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const backendUrl = useBackendUrl();
  const [showJournalPopover, setShowJournalPopover] = useState(false);
  const [account, setAccount] = useState();
  const initialValues = {
    adjustmentType: "shortage",
    Quantity: "",
    Unit: selectedItem?.Product_Model || "Each",
    UnitCost: selectedBatchItem.UnitCost,
    accountDesc: "",
    salesDate: moment(),
  };
  const schema = yup.object().shape({
    Quantity: yup.string().required(),
    UnitCost: yup.string().required(),
    accountDesc: yup.string().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      if (
        await ConfirmDialog({
          title: "Post Transaction",
          description: "Are you sure, you want to make this transaction",
        })
      ) {
        if (lowerCase(values.Unit) === "tons") {
          values.Quantity = tonsToPcs(
            values.Quantity,
            selectedItem.Item_Desc,
            itemMeasurements
          );
        }
        adjustQuantityMutation.mutate({
          ...values,
          Bar_Code: selectedBatchItem.Bar_Code,
          amount,
          account,
        });
      }
    },
  });

  /*  const setUp = () => {
    formik.setValues({
     
    });
  };

  useEffectOnce(() => {
    setUp();
  }); */

  const adjustQuantity = async (payload) => {
    let response = await fetch(`${backendUrl}/api/items/adjust-quantity`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const adjustQuantityMutation = useMutation(
    (payload) => adjustQuantity(payload),
    {
      onSuccess: ({ message, data }) => {
        refetch();
        toast.success(message);
        setSelectedBatchItem(null);
        setShowQuantityAdjustModal(false);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const handleSelectedExpense = (account) => {
    setAccount(account);
    formik.setFieldValue(
      "accountDesc",
      `${account?.AccountID || "..."} / ${account?.Description || "..."}`
    );
    setShowJournalPopover(false);
  };

  eventBus.useCustomEventListener("JOURNAL_ACCOUNT_CREATED", (account) => {
    handleSelectedExpense(account);
  });

  // appSettings.isGigc && Number(selectedBatchItem.VAT) > 0
  /* ? selectedBatchItem.VAT
  :  */

  const amount = useMemo(() => {
    return currency(formik.values.Quantity).multiply(formik.values.UnitCost)
      .value;
  }, [formik.values.Quantity, formik.values.UnitCost]);

  useEffect(() => {
    if (lowerCase(formik.values.Unit) === "pieces") {
      let measurement = itemMeasurements.find(
        (measurement) => measurement.Size === selectedItem.Item_Desc
      );
      measurement.Quantity = measurement?.Quantity || 1;

      const pcsUnitCost = currency(selectedBatchItem.UnitCost).divide(
        measurement.Quantity
      ).value;

      formik.setFieldValue("UnitCost", pcsUnitCost);
    } else {
      formik.setFieldValue("UnitCost", selectedBatchItem.UnitCost);
    }
  }, [formik.values.Unit /* , formik.values.UnitCost */]);

  return (
    <Modal
      show={showQuantityAdjustModal}
      onHide={() => {
        setSelectedBatchItem(null);
        setShowQuantityAdjustModal(false);
      }}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h6">
            Adjust Batch - {selectedBatchItem.ItemName}/
            {selectedBatchItem?.Bar_Code}
          </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1">
              Quantity In Stock:{" "}
              <ConvertQuantity
                quantity={selectedItem.Quantity}
                desc={selectedItem.Item_Desc}
              />
            </Form.Label>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="mb-1">Adjustment Type</Form.Label>
            <Select
              classNamePrefix={`form-select`}
              options={adjustmentTypeOptions}
              value={adjustmentTypeOptions.find(
                (el) => el.value === formik.values.adjustmentType
              )}
              onChange={({ value }) => {
                formik.setFieldValue("adjustmentType", value);
              }}
            />
          </Form.Group>

          <div className="row text-nowrap align-items-center">
            <Form.Group className="col-7 mb-3 pb-2">
              <Form.Label>Quantity</Form.Label>
              <NumberCustomInput
                placeholder="0"
                name="Quantity"
                value={formik.values.Quantity}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={formik.touched.Quantity && !!formik.errors.Quantity}
                onBlur={() => formik.setFieldTouched("Quantity", true)}
              />
              {formik.touched.Quantity && !!formik.errors.Quantity ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.Quantity}
                </span>
              ) : null}
            </Form.Group>

            <Form.Group className="col-5 mb-3 pb-2">
              <Form.Label className="mb-1">Unit</Form.Label>
              <Select
                classNamePrefix={`form-select`}
                options={unitsResolver(Units, formik.values.Unit)}
                value={Units.find((el) => el.value === formik.values.Unit)}
                onChange={({ value }) => {
                  formik.setFieldValue("Unit", value);
                }}
              />
            </Form.Group>
          </div>

          <Form.Group className="mb-3">
            <Form.Label>UnitCost</Form.Label>

            <CurrencyCustomInput
              name="UnitCost"
              value={formik.values.UnitCost}
              onValueChange={(value, name) => {
                formik.setFieldValue(name, value);
              }}
              isInvalid={formik.touched.UnitCost && !!formik.errors.UnitCost}
            />
            {formik.touched.UnitCost && !!formik.errors.UnitCost ? (
              <span className="custom-invalid-feedback">
                {formik.errors.UnitCost}
              </span>
            ) : null}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Amount</Form.Label>
            <CurrencyCustomInput
              type="text"
              name="Amount"
              value={amount}
              readOnly
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Account</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                name="account"
                value={formik.values.accountDesc}
                onChange={() => {}}
                readOnly
                isInvalid={
                  formik.touched.accountDesc && !!formik.errors.accountDesc
                }
              />
              {/* <Form.Control.Feedback type="invalid">
                {formik.errors.accountDesc}
              </Form.Control.Feedback> */}

              <Popover
                isOpen={showJournalPopover}
                onClickOutside={() => setShowJournalPopover(false)}
                content={() => (
                  <ExpensesSelector
                    handleSelectedExpense={handleSelectedExpense}
                    usage={"chart of accounts"}
                    onHide={() => setShowJournalPopover(false)}
                  />
                )}
                position="bottom"
              >
                <InputGroup.Text onClick={() => setShowJournalPopover(true)}>
                  <MagnifyIcon />
                </InputGroup.Text>
              </Popover>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Sales Date</Form.Label>
            <Datetime
              timeFormat={false}
              closeOnSelect={true}
              closeOnClickOutside={true}
              dateFormat="MMM DD, YYYY"
              name="salesDate"
              inputProps={{
                className: `date-input form-control ${
                  formik.touched.salesDate && !!formik.errors.salesDate
                    ? "is-invalid"
                    : ""
                }`,
                placeholder: "Select date",
                readOnly: true,
              }}
              value={formik.values.salesDate}
              onChange={(date) => {
                formik.setFieldValue("salesDate", date, true);
              }}
              onBlur={() => formik.setFieldTouched("salesDate", true)}
            />
          </Form.Group>

          <Button
            disabled={adjustQuantityMutation.isLoading}
            variant="primary"
            className="w-100 p-2"
            type="submit"
          >
            {adjustQuantityMutation.isLoading ? "Please wait…" : "Post"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
